<template>
  <NuxtLink :to="link" class="link">
    <section class="section-inspirations">
      <h2 class="title">{{ title }}</h2>
      <div class="inspirations-container">
        <div class="inspirations">
          <Icon name="wave-line" class="wave-line" />
          <div v-for="(imageAndText, index) in imageAndTexts" :key="index" class="item">
            <div class="relative">
              <TwicImg
                :src="imageAndText.image.data.attributes.url"
                class="img"
                ratio="2:1"
                :alt="`${index + 1}-inspiration-image`"
              />
              <div class="number-container">
                <span class="num">{{ index + 1 }}</span>
              </div>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="description" v-html="renderMarkdown(imageAndText.text).rendered"></div>
          </div>
        </div>
      </div>

      <SliderSwiperWrapper
        v-model="swiper"
        :space-between="0"
        slides-per-view="auto"
        class="inspirations-swiper"
      >
        <Icon name="wave-line" class="wave-line" />
        <SwiperSlide v-for="(imageAndText, index) in imageAndTexts" :key="index">
          <div class="item">
            <div class="relative">
              <TwicImg
                :src="imageAndText.image.data.attributes.url"
                class="img"
                ratio="2:1"
                :alt="`${index + 1}-inspiration-image`"
              />
              <div class="number-container">
                <span class="num">{{ index + 1 }}</span>
              </div>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="description" v-html="renderMarkdown(imageAndText.text).rendered"></div>
          </div>
        </SwiperSlide>
      </SliderSwiperWrapper>
    </section>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { renderMarkdown } from '@/lib/markdown';

import { ImageAndText } from '@/lib/strapi-types/components/ImageAndText';

import { SwiperBinding } from '@/lib/types/models/swiper';

defineProps<{
  title: string;
  link: string;
  imageAndTexts: ImageAndText[];
}>();

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/typography.scss';
@use '$/spacings.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';
@use '$/misc.scss';

.link {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.section-inspirations {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .title {
    font-size: typography.$font-size-xl;
    font-weight: 600;
    line-height: typography.$font-size-xl;
    text-align: center;
  }

  .inspirations-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0 spacings.$desktop-horizontal-lg;

    .inspirations {
      position: relative;

      justify-content: space-between;

      width: 100%;
      max-width: 950px;
      margin-top: spacings.$desktop-vertical-min;

      .wave-line {
        position: absolute;
        top: 16px;
        left: 32px;
      }

      .item {
        position: relative;
        flex-direction: column;
        max-width: 230px;

        .number-container {
          position: absolute;
          top: 50%;
          bottom: 0;
          left: 0;
          transform: translateZ(0) !important;

          align-items: center;
          justify-content: center;

          width: 80px;
          height: 80px;
          margin-top: 20px;

          background-color: white;
          border-radius: 100px;

          .num {
            font-size: typography.$font-size-xxl;
            font-weight: 600;
            color: colors.$green;
          }
        }

        .img {
          @include misc.twic-pics-border-radius(100%);

          width: 160px;
          height: 160px;
          border-radius: 100%;
        }

        .description {
          z-index: 2;

          flex-direction: column;

          margin-top: 15px;
          margin-left: 24px;

          font-size: typography.$font-size-lg;

          :deep(strong) {
            font-weight: 600;
            color: colors.$gold;
          }
        }
      }
    }
  }

  .inspirations-swiper {
    display: none;
  }
}

@include breakpoints.tablet() {
  .section-inspirations {
    .title {
      padding: 0 24px;
      font-size: typography.$mobile-sub-title;
    }

    .inspirations-container {
      display: none;
    }

    .inspirations-swiper {
      display: flex;
      padding: 24px;

      .wave-line {
        position: absolute;
        width: 100%;
        margin-left: 40px;
      }

      :deep(.swiper-wrapper) {
        justify-content: space-between;
      }

      .item {
        position: relative;

        flex-direction: column;
        justify-content: space-between;

        max-width: 200px;
        height: 100%;

        .number-container {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateZ(0) !important;

          align-items: center;
          justify-content: center;

          width: 53px;
          height: 53px;
          margin-top: 20px;

          background-color: white;
          border-radius: 100px;

          .num {
            font-size: typography.$font-size-xl;
            font-weight: 600;
            color: colors.$green;
          }
        }

        .img {
          @include misc.twic-pics-border-radius(100%);

          width: 104px;
          height: 104px;
          border-radius: 100%;
        }

        .description {
          flex-direction: column;
          margin-top: 26px;
          font-size: typography.$font-size-md;

          :deep(strong) {
            font-weight: 600;
            color: colors.$gold;
          }
        }
      }
    }
  }
}
</style>
